// If you want to override variables do it here
@import "variables";

// Import styles
@import "@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

.dropdown-toggle::after{
    content: none;
}
.footer-fixed .app-footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1020;
    height: 35px;
}
.form-group .icon-addon .form-control {
    border-radius: 25px;
}
.icon-addon {
    position: relative;
    color: #555;
    display: block;
}

    .icon-addon:after,
    .icon-addon:before {
        display: table;
        content: " ";
    }

    .icon-addon:after {
        clear: both;
    }

    .icon-addon.addon-md .glyphicon,
    .icon-addon .glyphicon,
    .icon-addon.addon-md .fa,
    .icon-addon .fa {
        position: absolute;
        z-index: 2;
       /* font-size: 14px;*/
        width: 20px;
        text-align: center;
        padding: 10px 0;
        top: 1px;
    }

        .icon-addon .fa.left-icon {
            left: 10px;
            margin-left: -2.5px;
        }

        .icon-addon .fa.right-icon {
            right: 10px;
            margin-right: -2.5px;
        }

    .icon-addon.addon-lg .form-control {
        line-height: 1.33;
        height: 46px;
      /*  font-size: 18px;*/
    }

        .icon-addon.addon-lg .form-control.left-icon {
            padding-top: 10px;
            padding-right: 16px;
            padding-bottom: 10px;
            padding-left: 40px !important;
        }

        .icon-addon.addon-lg .form-control.right-icon {
            padding-top: 10px;
            padding-right: 40px !important;
            padding-bottom: 10px;
            padding-left: 16px;
        }


    .icon-addon.addon-sm .form-control {
         /* height: 30px;
      font-size: 12px;*/
        line-height: 1.5;
    }

        .icon-addon.addon-sm .form-control.left-icon {
            padding-top: 5px;
            padding-right: 10px;
            padding-bottom: 5px;
            padding-left: 28px !important;
        }

        .icon-addon.addon-sm .form-control.right-icon {
            padding-top: 5px;
            padding-right: 28px !important;
            padding-bottom: 5px;
            padding-left: 10px;
        }


    .icon-addon.addon-lg .fa,
    .icon-addon.addon-lg .glyphicon {
       /* font-size: 18px;*/
        top: 4px;
    }

        .icon-addon.addon-lg .fa.left-icon,
        .icon-addon.addon-lg .glyphicon.left-icon {
            margin-left: 0;
            left: 11px;
        }

        .icon-addon.addon-lg .fa.right-icon,
        .icon-addon.addon-lg .glyphicon.right-icon {
            margin-right: 0;
            right: 11px;
        }


    .icon-addon.addon-md .form-control,
    .icon-addon .form-control {
        font-weight: normal;
    }

        .icon-addon.addon-md .form-control.left-icon,
        .icon-addon .form-control.left-icon {
            float: left;
            padding-left: 30px;
        }

        .icon-addon.addon-md .form-control.right-icon,
        .icon-addon .form-control.right-icon {
            float: right;
            padding-right: 30px;
        }


    .icon-addon.addon-sm .fa,
    .icon-addon.addon-sm .glyphicon {
       /* font-size: 12px;*/
        top: 1px;
    }

        .icon-addon.addon-sm .fa.left-icon,
        .icon-addon.addon-sm .glyphicon.left-icon {
            margin-left: 0;
            left: 7px;
        }

        .icon-addon.addon-sm .fa.right-icon,
        .icon-addon.addon-sm .glyphicon.right-icon {
            margin-right: 0;
            right: 5px;
        }




    .icon-addon .form-control:focus + .glyphicon,
    .icon-addon:hover .glyphicon,
    .icon-addon .form-control:focus + .fa,
    .icon-addon:hover .fa {
        color: #2580db;
    }
    .clear-input {
        text-decoration: none !important;
        color: #b3b3b3 !important;
    }
    
        .clear-input:hover {
            color: #2580db !important;
        }
        .iti__flag-box, .iti__country-name{
            color: black;
        }
        .iti__selected-dial-code {
            color: black !important;
        }